import React, {useRef} from 'react';
import './HeroSection.css';
import { useNavigate } from "react-router-dom";
import ShortVideosCarousel from "./ShortVideosCarousel";
import Gallery from "./Gallery";

function HeroSection() {

    const navigator = useNavigate();
    const PageTitle = useRef();
    const Buttons = useRef();


    return (
        <div className='hero-container' id="hero-section">

        </div>


    );
}

export default HeroSection;
{/*

            <div className="hero-container-card-price-indication">
                <CardItemPriceIndication
                    src={PriceIndication}
                    text='Calculate your price indication'
                    label='Price indication'
                    path='/request-container'
                />
            </div>
            <div className="hero-container-card-the-next-step">
                <CardItemTheNextStep
                    src={Statisfied}
                    text='Schedule the next step'
                    label='The next step'
                    path='/contact'
                />
            </div>

*/}
