// Classes.js

import React from 'react';
import './Classes.css';
import classesImage from '../assets/classesImage.png';
import trainingProgram from '../assets/trainingProgram.png';
function Classes() {
    return (
        <div className="classes-container">
            <div className="align-start-column">
                {/*<h1>CLASSES</h1>*/}
                <div className="classes-bg-container">
                {/*    <div className="class-schedule">*/}
                {/*        <div className="class-card-container">*/}
                {/*            <h3 className="class-card-title">MONDAY - FRIDAY</h3>*/}
                {/*            <div className="align-flex-row">*/}
                {/*                <p className="class-card-subtitle">Morning class</p>*/}
                {/*                <p className="class-time">8AM - 9:45AM</p>*/}
                {/*            </div>*/}
                {/*            <div className="align-flex-row">*/}
                {/*            <p className="class-card-subtitle">Afternoon class</p>*/}
                {/*            <p className="class-time">4PM - 5:45PM</p>*/}
                {/*            </div>*/}
                {/*            <br/>*/}
                {/*            <h3 className="class-card-title">SATURDAY</h3>*/}
                {/*            <div className="align-flex-row">*/}
                {/*                <p className="class-card-subtitle">Morning class</p>*/}
                {/*                <p className="class-time">8AM - 9:45AM</p>*/}
                {/*            </div>*/}
                {/*            <div className="align-flex-row">*/}
                {/*                <p className="class-card-subtitle">Afternoon class</p>*/}
                {/*                <p className="class-time">3PM - 4:45PM</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="personal-training-times">*/}
                {/*    <h3 className="class-card-title">PERSONAL TRAINING</h3>*/}
                {/*    <p className="class-cta">BOOKING REQUIRED</p>*/}
                {/*    <p className="class-card-privates-subtitle">MONDAY - SATURDAY</p>*/}
                {/*    <p className="class-time">10AM - 3PM</p>*/}
                {/*    <p className="class-time">6PM - 7PM</p>*/}
                    <img src={classesImage} alt="Gym Image" className="gym-image"/>

                </div>
            </div>
            <div className="align-end-column">
                <img src={trainingProgram} alt="" className="gym-image"/>

            </div>


        </div>
    );
}

export default Classes;



{/* Additional Information */}
{/*                <div className="additional-info">
                    <p>Instructor: John Doe</p>
                    <p>Contact Email: info@example.com</p>
                </div>*/}