import React from "react";
import './GoogleMapsEmbed.css';

const GoogleMapsEmbed = () => {
    return (
        <div className="map-container">
            <iframe
                title="Google Maps"
                width="600"
                height="450"
                frameBorder="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3831.7208941660706!2d98.31644389464074!3d7.776899579874164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3050296cfae0a199%3A0xf628ed7ed50c7a48!2sKB%20Muaythai%20Phuket!5e0!3m2!1snl!2sth!4v1707361938891!5m2!1snl!2sth"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                style={{ height: "100%" }} /* Set the height of the iframe to 100% */
            ></iframe>
        </div>
    );
};

export default GoogleMapsEmbed;

