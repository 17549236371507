import React from 'react';
import './Contact.css';
import ContactSocial from "./ContactSocial";
import WhatsApp from '../assets/WhatsApp.png';
import Instagram from '../assets/Instagram.png';
import Facebook from '../assets/Facebook.png';
import EmailIcon from '../assets/EmailIcon.png';
import {TbHandClick} from "react-icons/tb";

function Contact() {
    return (
        <div className="contact-container" id="contact-section">

            <h1>CONTACT US</h1>
            <p>+66 93 576 3696 | Kbmuaythai01@gmail.com</p>
            <p>Q8G9+QJV, Rawai, Mueang Phuket District, Phuket 83100, Thailand</p>
            <div className="contact-buttons-container">
                <div className="contact-button">
                    <a href="https://api.whatsapp.com/send?phone=+66935763696" target='_blank'
                       className="whatsapp-contact-button">
                        <TbHandClick className="message-pointer-icon"/> Message us on WhatsApp
                        <img src={WhatsApp} alt="whatsapp-icon" className="contact-icon"/>
                    </a>
                </div>
                <div className="contact-button">
                    <a href="https://www.instagram.com/k.b.muaythai/" target='_blank'
                       className="instagram-contact-button">
                        <TbHandClick className="message-pointer-icon"/> Message us on Instagram
                        <img src={Instagram} alt="instagram-icon" className="contact-icon"/>
                    </a>
                </div>
                <div className="contact-button">
                    <a href="https://www.facebook.com/profile.php?id=100082954071394/" target='_blank'
                       className="facebook-contact-button">
                        <TbHandClick  className="message-pointer-icon"/> Message us on Facebook
                        <img src={Facebook} alt="facebook-icon" className="contact-icon"/>
                    </a>
                </div>
                <div className="contact-button">
                    <a href="mailto:Kbmuaythai01@gmail.com" className="email-contact-button">
                        <TbHandClick  className="message-pointer-icon"/> Or send us an Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </a>
                    <img src={EmailIcon} alt="email-icon" className="contact-icon"/>
                </div>

            </div>
        </div>
    );
}

export default Contact;
