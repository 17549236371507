import React from 'react';
import WhatsApp from "../assets/WhatsApp.png";
import Facebook from "../assets/Facebook.png";
import Instagram from "../assets/Instagram.png";
import './ContactSocial.css';

function ContactSocial() {
    return (
        <div className="contact-social">
            <div className="contact-social-redirect-title">
                <p>Contact us</p>

                <div className="contact-social-align-row">
                <a href="https://www.instagram.com/k.b.muaythai/" target="_blank" rel="noopener noreferrer">
                    <img src={WhatsApp} alt="WhatsApp" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100082954071394/" target="_blank" rel="noopener noreferrer">
                    <img src={Facebook} alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/k.b.muaythai/" target="_blank" rel="noopener noreferrer">
                    <img src={Instagram} alt="Instagram" />
                </a>
                </div>
            </div>

        </div>
    );
}

export default ContactSocial;