import React from 'react';
import PRR_OUTTAHERRR from '../assets/PRR_OUTTAHERRR.png';
import './TrainingSchedule.css';
function TrainingSchedule() {
    return (
        <div className="training-schedule">
            <h1 id="schedule-section">TRAINING SCHEDULE</h1>
            <div className="schedule-display">
                <div className="schedule-card">
                    <h2>MONDAY</h2>
                    <h3>MORNING CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>MUAYTHAI TECHNIQUE</p>
                    <p>SPEED BAG 3 ROUNDS</p>
                    <br/>
                    <h3>AFTERNOON CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>SPARRING MUAYTHAI</p>
                    <p>SPEED 3 ROUND</p>
                </div>
                <div className="schedule-card">
                    <h2>TUESDAY</h2>
                    <h3>MORNING CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>CLINCH TECHNIQUE</p>
                    <p>JUMP-KNEE 3 ROUNDS</p>
                    <br/>
                    <h3>AFTERNOON CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>SPARRING MUAYTHAI</p>
                    <p>CLINCH</p>
                </div>
                <div className="schedule-card">
                    <h2>WEDNESDAY</h2>
                    <h3>MORNING CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>MUAYTHAI TECHNIQUE</p>
                    <p>LOW KICK</p>
                    <br/>
                    <h3>AFTERNOON CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>SPARRING MUAYTHAI</p>
                    <p>LOW KICK</p>
                </div>
                <div className="schedule-card">
                    <h2>THURSDAY</h2>
                    <h3>MORNING CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>MUAYTHAI TECHNIQUE</p>
                    <p>WAI KRU MUAYTHAI</p>
                    <br/>
                    <h3>AFTERNOON CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>SPARRING MUAYTHAI</p>
                    <p>WAI KRU MUAYTHAI</p>
                </div>
                <div className="schedule-card">
                    <h2>FRIDAY</h2>
                    <h3>MORNING CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>CLINCH TECHNIQUE</p>
                    <p>JUMP-KNEE 3 ROUNDS</p>
                    <br/>
                    <h3>AFTERNOON CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>SPARRING MUAYTHAI</p>
                    <p>CLINCH</p>
                </div>
                <div className="schedule-card">
                    <h2>SATURDAY</h2>
                    <h3>MORNING CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>MUAYTHAI TECHNIQUE</p>
                    <p>SPEEDBAG 5 ROUNDS</p>
                    <br/>
                    <h3>AFTERNOON CLASS</h3>
                    <p>PADWORK</p>
                    <p>BAGWORK</p>
                    <p>SPARRING</p>
                    <p>CARDIO</p>
                </div>
                <br/>
                <img src={PRR_OUTTAHERRR} alt="boxing-gloves-image"/>
            </div>
        </div>
    );
}

export default TrainingSchedule;
