import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Header.css';

function Header() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleLinkClick = (id) => {
        toggleMenu(); // Close the menu when a link is clicked
        scrollTo(id); // Scroll to the corresponding section
    };

    return (
        <header className={`m-header ${isMenuOpen ? 'open' : ''}`}>
            <div className="title-and-menu">
                <h1>K.B. MUAYTHAI PHUKET</h1>
                <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>

            <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/" onClick={() => handleLinkClick('hero-section')}>HOME</Link></li>
                    <li><Link to="/" onClick={() => handleLinkClick('trainers-section')}>TRAINERS</Link></li>
                    <li><Link to="/" onClick={() => handleLinkClick('schedule-section')}>SCHEDULE</Link></li>
                    <li><Link to="/" onClick={() => handleLinkClick('prices-section')}>PRICES</Link></li>
                    <li><Link to="/" onClick={() => handleLinkClick('muay-thai-technique-section')}>MUAYTHAI TECHNIQUE</Link></li>
                    <li><Link to="/" onClick={() => handleLinkClick('contact-section')}>CONTACT</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;


