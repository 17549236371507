import React from 'react';
import KB_GIRL from '../assets/KB_GIRL.png';
import './Prices.css';
function Prices() {
    return (
        <div className="outer-prices-container">
        <div className="prices-container">
            <div className="p-align-start">
                <h2 className="training-prices-title" id="prices-section">TRAINING PRICES</h2>
                <p><strong>PER DAY</strong></p>
                <p><strong>1</strong> CLASS PER DAY <strong>400.-</strong></p>
                <p><strong>2</strong> CLASS PER DAY <strong>600.-</strong></p>
                <br/>
                <p><strong>WEEKLY</strong></p>
                <p><strong>1</strong> CLASS PER DAY <strong>2,100.-</strong></p>
                <p><strong>2</strong> CLASS PER DAY <strong>3,200.-</strong></p>
                <br/>
                <p><strong>MONTHLY</strong></p>
                <p><strong>1</strong> CLASS PER DAY <strong>9,000.-</strong></p>
                <span><strong>FREE</strong> 1 MUAYTHAI SHIRT</span>
                <p><strong>2</strong> CLASS PER DAY <strong>11,000.-</strong></p>
                <span><strong>FREE</strong> 1 MUAYTHAI SHORT</span>
                <br/>
                <br/>
                <p><strong>2 MONTHLY</strong></p>
                <p><strong>1</strong> CLASS PER DAY <strong>16,000.-</strong></p>
                <p><strong>2</strong> CLASS PER DAY <strong>18,000.-</strong></p>
            </div>
            <div className="p-align-center">
                <h2 className="ten-session-pass-title">10 SESSION PASS</h2>
                <p><strong>3,500.-</strong></p>
                <span>MUST USE WITHIN 1 MONTH</span>
                <br/>
                <br/>
                <p><strong>PRIVATE 1 ON 1 TRAINING</strong></p>
                <p><strong>700.-</strong> BAHT / HOUR</p>
                <p><strong>6,500.-</strong> BAHT / <strong>10</strong> HOUR</p>
                <p><strong>12,500.-</strong> BAHT / <strong>20</strong> HOUR</p>

                <br/>
                <br/>
                <p><strong>PRIVATE 1 ON 2 TRAINING</strong></p>
                <p><strong>1000.-</strong> BAHT / HOUR</p>
                <p><strong>9,500.-</strong> BAHT / <strong>10</strong> HOUR</p>
                <p><strong>18,500.-</strong> BAHT / <strong>20</strong> HOUR</p>

            </div>
            <div className="p-align-end">
                <img src={KB_GIRL} alt="Girl posing at KBMT"/>
            </div>

        </div>
        </div>
    );
}

export default Prices;
