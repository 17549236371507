import React from 'react';
import './Homepage.css';
import HeroSection from "./HeroSection";
import Classes from "./Classes";
import Trainers from "./Trainers";
import TrainingSchedule from "./TrainingSchedule";
import Prices from "./Prices";
import Contact from "./Contact";
import GoogleMapsEmbed from "./GoogleMapsEmbed";
import MuayThaiTechnique from "./MuayThaiTechnique";
import NewsBanner from "./NewsBanner";
import Gallery from "./Gallery";

function Homepage() {

    return (
        <div className="homepage-container">
{/*
            <NewsBanner />
*/}
            <HeroSection />
            <Gallery />
{/*
            <ShortVideosCarousel />
*/}
{/*
            <ContactSocial />
*/}
            <Classes />
            <Trainers />
            <TrainingSchedule />
            <MuayThaiTechnique />
            <Prices />
            <Contact />
            <GoogleMapsEmbed />
        </div>
    );
}

export default Homepage;
