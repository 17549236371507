import React from 'react';
import './App.css';
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import {Navigate, Route, Routes} from "react-router-dom";
import Footer from "./components/Footer";
import Prices from "./components/Prices";
import Contact from "./components/Contact";
import TrainingSchedule from "./components/TrainingSchedule";
import Trainers from "./components/Trainers";

function App() {

    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<Homepage />} />

                <Route path="/trainers" element={<Trainers />} />
                <Route path="/schedule" element={<TrainingSchedule />} />
                <Route path="/prices" element={<Prices />} />
                <Route path="/contact" element={<Contact />} />


            </Routes>
{/*
            <Footer />
*/}
        </>
    );
}

export default App;
