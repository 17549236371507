import React from 'react';
import ShortVideosCarousel from "./ShortVideosCarousel";
import './MuayThaiTechnique.css';

function MuayThaiTechnique() {
    return (
        <div className="muay-thai-technique" id="muay-thai-technique-section">
            {/* Your application code here */}
            <h1 className="muay-thai-technique-title">MUAYTHAI TECHNIQUE</h1>
            <div className="muay-thai-technique-content">
                <ShortVideosCarousel />
            </div>

        </div>
    );
}

export default MuayThaiTechnique;
